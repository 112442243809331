import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';
import { environment } from 'environments/environment';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InterceptorService } from './services/interceptors/interceptor.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AuthService } from './services/auth/auth.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppDateAdapter, APP_DATE_FORMATS } from './shared/date-adapter';
import { AgmCoreModule } from '@agm/core';
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import { SharedModule } from './shared/shared.module';
import { OpenPayService } from './services/openPay-service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AuthGuardCancel } from './services/auth/auth.guard';

// Importaciones de ngx-translate
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireModule } from '@angular/fire/compat';
//import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { IdbService } from './services/Idb-service ';
import { MessagingService } from './services/messaging.service';
import { getMessaging, getToken } from 'firebase/messaging';


// Registrar idioma español
registerLocaleData(localeEs, "es-MX");

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

// Función para cargar archivos de traducción
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        // NgxMaterialTimepickerModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        // MaterialTimePickerModule,
        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,
        MatSelectModule,
        MatTooltipModule,
        HttpClientModule,
        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        ImageCropperModule,
        NgbModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyC51KiGonUY5NWqf423NNeHaKMTcT5Z46Q'
        }),

        NgxMaskModule.forRoot(),
        SharedModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
       /* provideMessaging(() => {
            const messaging = getMessaging();
            getToken(messaging, { vapidKey: 'BHytOeKdaw6xi5FtDeaASPmdxlQyDP13AbvzHTLiBkkvaaTfhxasZFskFDr56G2iQg1QRZoR9xdqoqwNbXKmLnc' }).then((token) => {
                debugger;
              console.log('FCM Token:', token);
            }).catch((error) => {
              console.error('Error getting FCM token:', error);
            });
            return messaging;
          }),*/

        // Configuración de ngx-translate
        //TranslateModule
    ],
    bootstrap: [
        AppComponent
    ],
    exports: [

    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        MessagingService,
        IdbService,
        AuthService,
        AuthGuardCancel,
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        { provide: 'EP_VENTA_RAPIDA', useFactory: ()=>environment.endpointVentaRapida },
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        },
        // Aquí configuras el idioma por defecto si no se detecta otro
        /*{
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: (translate: TranslateService) => {
                const lang = translate.getBrowserLang();
                translate.use(lang.match(/en|es/) ? lang : 'en');
                return translate.currentLang;
            }
        },*/

        {
            provide: LOCALE_ID,
            useValue: 'es-MX'  // Forzamos a usar 'es-MX'
        },
        OpenPayService
    ], schemas: [
        NO_ERRORS_SCHEMA,
        CUSTOM_ELEMENTS_SCHEMA
    ]
}
)export class AppModule {

}
export function getBaseUrl() {
    return environment.baseUrl;
}
function provideMessaging(arg0: () => any): any[] | import("@angular/core").Type<any> | import("@angular/core").ModuleWithProviders<{}> {
    throw new Error('Function not implemented.');
}

