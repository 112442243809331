import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';
import { Auth } from '@aws-amplify/auth';

if (environment.production) {
    enableProdMode();
}

Auth.configure({

    region: environment.amplify.Auth.region,
    identityPoolRegion: environment.amplify.Auth.region,
    userPoolId: environment.amplify.Auth.userPoolId,
    userPoolWebClientId: environment.amplify.Auth.userPoolWebClientId,
    //  authenticationFlowType: environment.amplify.Auth.authenticationFlowType
});
platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
/*
    if ('serviceWorker' in navigator) {
   // debugger;
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered:', registration);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }*/
