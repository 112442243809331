import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IDBPDatabase, openDB } from 'idb';

@Injectable({
  providedIn: 'root'
})
export class IdbService {
  public _dataChange: Subject<any> = new Subject<any>();
  public Messages: Subject<[]> = new Subject<[]>();
  private _dbPromise;

  constructor() {
  }

  async connectToIDB() {
    this._dbPromise = await openDB('invox_notifications', 1);
  }



  deleteItems(target: string, value: any) {
    // this._dbPromise.then((db: any) => {
    //   const tx = db.transaction(target, 'readwrite');
    //   const store = tx.objectStore(target);
    //   store.delete(value);
    //   this.getAllData(target).then((items: any) => {
    //     this._dataChange.next(items);
    //   });
    //   return tx.complete;
    // });
    const tx = this._dbPromise.transaction(target, 'readwrite');
    const store = tx.objectStore(target);
    store.delete(value);
    return tx.complete;
  }

  async getAllData(target: string) {
    // return this._dbPromise.then((db: any) => {
    //   const tx = db.transaction(target, 'readonly');
    //   const store = tx.objectStore(target);
    //   return store.getAll();
    // });
    const tx = this._dbPromise.transaction(target, 'readonly');
    const store = tx.objectStore(target);
    let mensajes = await store.getAll();
    this._dataChange.next(mensajes);
  }

  dataChanged(): Observable<any> {
    return this._dataChange;
  }
}