import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import { BehaviorSubject, catchError, filter, from, map, Observable, of } from "rxjs";
import { tap, first } from 'rxjs/operators';
import { SharedService } from "../shared.service";
import { SweetAlertMsgService } from "../sweet-alert-msg.service";

@Injectable()
export class AuthService {

    public loggedIn: BehaviorSubject<boolean>;

    constructor(
        private router: Router,
        private _msg: SweetAlertMsgService,
        private _shared: SharedService
    ) {
        this.loggedIn = new BehaviorSubject<boolean>(false);
    }
    public changePassword(oldPassword: string, newPassword: string): Observable<any> {
        return from(Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            // .then(data => console.log(data))
            // .catch(err => console.log(err))
        ).pipe(
            tap((e) => {
                return of(e);
                //    ;
            }),
            catchError(error => {
                return of(this.parseError(error));
            })
        );
    }

    public sendCodeResetPassword(userName: string): Observable<any> {
        return from(
            Auth.forgotPassword(userName)

            // .then(data => {
            //     // data
            //     console.log(data);
            // })
            // .catch(err => {
            //     let text = `${err}`;
            //     let lstText = text.split(":");
            //     let objError = {
            //         code: lstText[0],
            //         msg: lstText[1].trim()
            //     };
            //     return of(objError);
            // })
        ).pipe(
            tap((e) => {
                return of(e);
                //    ;
            }),
            catchError(error => {
                return of(this.parseError(error));
            })
        );
    }
    public submitForgotPassword(username: string, code: string, new_password: string): Observable<any> {
        return from(
            Auth.forgotPasswordSubmit(username, code, new_password)
            // .then(data => {
            //     return of(data);
            //     // console.log(data);
            // })
            // .catch(err => {
            //     return of(this.parseError(err));
            // })
        ).pipe(
            tap((e) => {
                return of(e);
                //    ;
            }),
            catchError(error => {
                return of(this.parseError(error));
            })
        );
    }
    public resendCodeConfirmation(usuario) {
        return from(
            Auth.resendSignUp(usuario)
                .then(data => {
                    return data
                })
                .catch(err => console.log(err))
        );
    }
    public getCurrentUser(): Observable<any> {
        return from(Auth.currentUserPoolUser());
    }
    public getCurrentPoolUser(): Promise<any> {
        return Auth.currentUserPoolUser();
    }
    public completeNewPassword(user: any, newPassword: string): Observable<any> {
        return from(Auth.completeNewPassword(
            user,               // the Cognito User Object
            newPassword, {}
        ));
    }
    public getAccessToken(): Observable<string> {
        let token: string;
        return from(
            Auth.currentSession()
        ).pipe(
            first(),
            filter(value => {
                //  ;
                return value !== null && value !== undefined;
            }),

            map(
                (session) => {
                    //  ;
                    return session.getIdToken().getJwtToken();
                }
            )

        );
    }
    /** signup */
    public signUp(email: string, password: string, apellidos: string, tel: string, nombres: string, rol: string = "0"): Observable<any> {
        return from(Auth.signUp({
            username: email,
            password: password,
            attributes: {
                "name": nombres,
                'custom:rolId': rol,
                'custom:apellidos': apellidos,
                'custom:tel': tel
            }
        })).pipe(
            tap((e) => {
                return of(e);
                //    ;
            }),
            catchError(error => {
                return of(this.parseError(error));
            })
        );
    }

    /** confirm code */
    public confirmSignUp(email, code): Observable<any> {
        return from(Auth.confirmSignUp(email, code)).pipe(
            tap((e) => {
                return of(e);
                //    ;
            }),
            catchError(error => {
                return of(this.parseError(error));
            })
        );
    }

    public signIn(email, password): Observable<any> {
        return from(Auth.signIn(email, password))
            .pipe(
                tap((e) => {
                    //    ;
                    this.loggedIn.next(true);
                }),
                catchError(error => {
                    this.loggedIn.next(false);
                    let text = `${error}`;
                    let lstText = text.split(":");
                    let objError = {
                        code: lstText[0],
                        msg: lstText[1].trim()
                    };
                    return of(objError);
                })
            );
    }


    public isAuthenticated(): Observable<boolean> {
        return from(Auth.currentAuthenticatedUser())
            .pipe(
                map(result => {

                    this.loggedIn.next(true);
                    return true;
                }),
                catchError(error => {
                    //   ;
                    this.loggedIn.next(false);
                    return of(false);
                })
            );
    }

    /** signout
     * Si es 0 = Login normal de usuario
     * Si es 1 = Login para cancelar cuenta
    */
    public signOut(lado: number = 0) {
        from(Auth.signOut())
            .subscribe({
                next: result => {

                    this.loggedIn.next(false);
                    //   this._shared.datosUser = null;
                    if (lado === 0) {
                        this.router.navigateByUrl('/inicio');
                    }
                    else {
                        this.router.navigateByUrl('/cancel/sign-in');
                    }
                    // location.href = "/login";
                },
                error: error => console.log(error)
            }
            );
    }

    public async changeRol(rol: string): Promise<string> {
        let user = await Auth.currentAuthenticatedUser();
        return Auth.updateUserAttributes(user, {
            'custom:rol': `${rol}`
        });
    }

    public async updateDataCognito(nombre: string, apellidos: string, tel: string): Promise<string> {
        let user = await Auth.currentAuthenticatedUser();
        return Auth.updateUserAttributes(user, {
            'name': `${nombre}`,
            'custom:apellidos': `${apellidos}`
            // 'custom:tel': `${tel}`
        });
    }
    public async updateOnlyTelCognito(tel: string): Promise<string> {
        let user = await Auth.currentAuthenticatedUser();
        return Auth.updateUserAttributes(user, {
            'custom:tel': `${tel}`
        });
    }

    private parseError(error): DataError {
        let text = `${error}`;
        let lstText = text.split(":");
        let objError = {
            code: lstText[0],
            msg: lstText[1].trim()
        };
        return objError;
    }
    evaluarMensajesCognito(obj, mail: string = "", pantalla: string = "") {
        if (obj.code === "UserNotConfirmedException") {
            this._msg.alertWarning("Atención", "El usuario no ha sido confirmado. Favor de confirmarlo.");
            this.router.navigateByUrl('/confirmation/confirm');
        }
        if (obj.code === "NotAuthorizedException") {
            let msg = "El usuario o contraseña son incorrectos.";
            if (pantalla === "changePassword") {
                msg = "La contraseña actual es incorrecta, favor de intentarlo de nuevo.";
            }
            if (pantalla === "confirm") {
                msg = "El correo electrónico ya está confirmado.";
            }
            this._msg.alertWarning("Atención", msg);
        }
        if (obj.code === "LimitExceededException") {
            this._msg.alertWarning("Atención", "Se han producido demasiado intentos fallidos. Intente después de 10 minutos.");
        }
        if (obj.code === 'UserNotFoundException') {
            this._msg.alertError("Error", "El usuario no fue encontrado.");
        }
        if (obj.code === 'CodeDeliveryDetails') {
            this._msg.alertError("Error", "Ocurrió un error con la sesión.");
        }
        if (obj.code == "CodeMismatchException") {
            this._msg.alertWarning("Error", "El código de verificación es inválido.");
        }
        if (obj.code == "ExpiredCodeException") {
            this.resendCodeConfirmation(mail).subscribe(res => {
                this._msg.alertWarning("Atención", "El código ingresado ya expiro, se le ha enviado un código válido a su correo electrónico.");
            });
        }
        if (obj.code == "UsernameExistsException") {
            this._msg.alertWarning("Atención", "El usuario capturado ya existe.");
        }
        if (obj.challengeName !== undefined) {
            if (obj.challengeName === "NEW_PASSWORD_REQUIRED") {
                // this.objUsuario = data;
                // this.cambiarPassword = true;
                this._shared.resetPassObj = obj;
                this.router.navigate(["/reset-password"]);
            }
        }

        /**
         * Códigos de respuesta de error de Cognito
         * https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pool-managing-errors.html
         */
    }
    public async onRemoveAccount(): Promise<any> {
        // return from(Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
        //     user.deleteUser(data => {
        //         console.log(data);
        //         return data;
        //     })
        //     //   .catch(err => console.log(err))
        // }));
        try {
            await Auth.deleteUser();
            console.log('Usuario eliminado exitosamente');
          } catch (error) {
            console.error('Error al eliminar usuario:', error);
          }
    }
}
interface DataError {
    code: string;
    msg: string;
}
