import { Injectable } from '@angular/core';

import { take, map, switchMap, catchError } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, from, of, pipe } from 'rxjs';


import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
//import { DBkeys } from './db-Keys';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { 
  getMessaging, 
  getToken, 
  onMessage, 
  isSupported,
  Messaging,
  MessagePayload,
} from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  public static readonly ID_TOKEN = "id_token";
  public static readonly PROFILE_UPDATED_KEY = "profilalreadyeupdated";
  currentMessage = new BehaviorSubject(null);
  token$: Observable<any>;
  message$: Observable<any>;
  private messaging: Messaging | null = null;
  private isPermissionGranted = new BehaviorSubject<boolean>(false);
  private currentToken = new BehaviorSubject<string | null>(null);

  cognitoUser = null;
  constructor(//public messaging: AngularFireMessaging,
    private config: AppConfigService,
    private http: HttpClient,) {
      //this.initializeMessaging();
  }
    /**
   * Initialize the Firebase messaging service
   */
    public async initializeMessaging(): Promise<void> {
      try {
        // Check if browser supports Firebase Messaging
        const isMessagingSupported = await isSupported();
        
        if (!isMessagingSupported) {
          console.warn('Firebase Cloud Messaging is not supported in this browser');
          return;
        }
        initializeApp(environment.firebase);
        this.messaging =getMessaging();
        
        // Set up message listener
        if (this.messaging) {
          this.listenForMessages();
         /* onMessage(this.messaging, (payload) => {
            debugger;
            console.log('Message received:', payload);
            this.currentMessage.next(payload);
          });*/
        }
        
        // Check if permission is already granted
        if (Notification.permission === 'granted') {
          this.isPermissionGranted.next(true);
        }
        
      } catch (error) {
        console.error('Error initializing messaging service:', error);
      }
    }

    /**
   * Request permission for notifications
   * @returns Observable of permission status (true if granted)
   */
    async requestPermission(): Promise<boolean> {
      if (!this.messaging) {
          return false;
      }
  
      try {
          const permission = await Notification.requestPermission();
          const permissionGranted = permission === 'granted';
          this.isPermissionGranted.next(permissionGranted);
          
          if (permissionGranted) {
              await this.getTokenAndStore();
          }
          
          return permissionGranted;
      } catch (error) {
          console.error('Error requesting notification permission:', error);
          return false;
      }
  }
  
  
  /**
   * Get device token and store it
   * @returns Observable of the FCM token
   */
  private getTokenAndStore(): Promise<string | null> {
    if (!this.messaging) {
      return Promise.resolve(null);
    }
    return new Promise((resolve, reject) => {
      getToken(this.messaging, { vapidKey: environment.firebase.vapidKey})
          .then((token) => {
              if (token) {
                  console.log('FCM token obtained:', token);
                  this.currentToken.next(token);
                  resolve(token);
              } else {
                  console.warn('No token received');
                  this.currentToken.next(null);
                  resolve(null);
              }
          })
          .catch((error) => {
              console.error('Error getting FCM token:', error);
              this.currentToken.next(null);
              reject(error);
          });
  });
  }


  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  

  deleteToken() {
    
  }


  // Listen for incoming messages using onMessage
  listenForMessages() {
    onMessage(this.messaging, (payload) => {
      console.log('Message received:', payload);
      this.currentMessage.next(payload);
    });
  }

  subscribeToTopic(userId) {
    const url = `${environment.baseUrl}notificacionescliente/suscribir-topico/${encodeURI(userId)}&${encodeURI(this.currentToken.value)}`;
    return firstValueFrom(this.http.post<any>(url, {}));
  }
  unSubscribeFromTopic(userId) {
    // console.log(encodeURI(token), token);
    const url = `${environment.baseUrl}notificacionescliente/desuscribir-topico/${encodeURI(userId)}&${encodeURI(this.currentToken.value)}`;
    return this.http.post<any>(url, {});
  }
  /**
   * Get the current received message
   * @returns Observable of the received message
   */
  receiveMessage(): Observable<MessagePayload | null> {
    return this.currentMessage.asObservable();
  }
  /**
   * Manually refresh the FCM token
   * @returns Observable of the new token
   */
  refreshToken(): Promise<string | null> {
    return this.getTokenAndStore();
  }
}
