<div *ngIf="show" class="modal-container">
    <div class="modal-content" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="center center">
            <img src="assets/images/logo/logo-spin.png" class="logo-spinner" />
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
    </div>
</div>
