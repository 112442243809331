import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from './services/shared.service';
import { environment } from 'environments/environment';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from './services/messaging.service';
import { firstValueFrom, Subscription, tap } from 'rxjs';

//declare var OpenPay:any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{
    lastMessage: any;
    private subscription: Subscription = new Subscription();
    /**
     * Constructor
     */
    constructor(
        public _shared: SharedService,
        private router: Router,
        private _auth: AuthService,
        private messagingService: MessagingService
    ) {


        //    debugger;
        /* OpenPay.setId(environment.openPay.merchantId );
         OpenPay.setApiKey(environment.openPay.apiKey);
         OpenPay.setSandboxMode(true);
         let token=OpenPay.token.create({
             "card_number":"4111111111111111",
             "holder_name":"Juan Perez Ramirez",
             "expiration_year":"24",
             "expiration_month":"12",
             "cvv2":"110",

       }, (par)=>{
         debugger;
         console.log(par);
       }, (error)=>{
         debugger;
       });

         console.log(OpenPay);
         console.log(token);*/
        _auth.isAuthenticated().subscribe({
            next: async data => {
              
               await  this.initilizeNotifications();
                let canc = localStorage.getItem("fcancel");
                if (canc !== null) {
                    this.router.navigate(["/cancel"]);
                }
            }, error: data => {
            }
        });
    }
    async getCognitoUser() {
        return ((await this._auth.getCurrentPoolUser()) || { attributes: null }).attributes;
      }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    async initilizeNotifications() {
        await this.messagingService.initializeMessaging();
        const user= await this.getCognitoUser();
        let granted = await  this.messagingService.requestPermission();
        if(granted) {
            await this.messagingService.subscribeToTopic(user.sub);
        }
        /*this.messagingService.requestPermission().pipe(
            tap(async granted => {
                debugger;
                if(granted) {
                    await this.messagingService.subscribeToTopic(user.sub);
                }
            })
        ).subscribe();*/
    }
    requestNotificationPermission() {
        if ('Notification' in window) {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
              this.showNotification('Welcome!', 'Notifications are enabled.');
            } else {
              console.log('Notification permission denied.');
            }
          });
        } else {
          console.log('This browser does not support notifications.');
        }
      }
      showNotification(title: string, body: string) {
        if ('Notification' in window && Notification.permission === 'granted') {
          new Notification(title, { body });
        }
      }
    
    async ngOnInit(): Promise<void> {
        this.subscription.add(
            this.messagingService.receiveMessage().subscribe(message => {
                //debugger;
              if (message) {
                this.lastMessage = message;
                if ('Notification' in window && Notification.permission === 'granted') {
                    this.showNotification(message.data.Title,  message.data.Body );
                  }
              }
            })
          );
          this.requestNotificationPermission();
    }
}
