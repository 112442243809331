export const environment = {
    nombre:'AERO CON TAXI',
    production: true,
    hmr: false,
    tema:'theme-amber',
    env:'integracion',
    colorPrincipalhexa:'#FF4713',
    rutaimagenes:'assets/images/logo/aero/',
    baseUrl: 'https://ry66kit3l7.execute-api.us-west-2.amazonaws.com/Prod/api/', // Produccion
    endpointPublic: 'https://ry66kit3l7.execute-api.us-west-2.amazonaws.com/Prod/cliente',
    endpointVentaRapida: "https://ry66kit3l7.execute-api.us-west-2.amazonaws.com/Prod/venta-rapida",
    amplify: {
        Auth: {
            region: 'us-west-2',
            /* =====> PRODUCCIÓN <===== */
            userPoolId: 'us-west-2_bVCJgxepg',
            userPoolWebClientId: '5khhi0tqf778sgbl3fmau8g3ru'
            
        }
    },
    openPay:{
        apiKey: "pk_131e98e16ef441599265579e9ad12e9d",
        merchantId:"m0edznwalqbjbuwxpx6e",
        modoSandbox: false
    },
    firebase: {
        apiKey: "AIzaSyD5dYgrvhzWcjDP5pQ2hJSZeWPV4_WiTkk",
        authDomain: "aerocontaxiapp.firebaseapp.com",
        projectId: "aerocontaxiapp",
        storageBucket: "aerocontaxiapp.firebasestorage.app",
        messagingSenderId: "1035145606309",
        appId: "1:1035145606309:web:99949abe993871aa27494c",
        measurementId: "G-EC40X66NVQ",
        vapidKey: "BHytOeKdaw6xi5FtDeaASPmdxlQyDP13AbvzHTLiBkkvaaTfhxasZFskFDr56G2iQg1QRZoR9xdqoqwNbXKmLnc"
    },  
};
